import { isModeE2E } from '@utils';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import router from './routes/routes';

async function initPortal() {
  if (isModeE2E) {
    const { setupMocks } = await import('./tests/e2e/__mocks__/core/setup');
    await setupMocks();
  }

  const container = document.getElementById('root')!;
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}

initPortal();
