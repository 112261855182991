import { PropsWithChildren } from 'react';
import { FlowbiteWrapper } from './FlowbiteWrapper';
import { ToastNotification } from './ToastNotification';

export function ThemeProvider({ children }: PropsWithChildren) {
  return (
    <>
      <ToastNotification />
      <FlowbiteWrapper>{children}</FlowbiteWrapper>
    </>
  );
}
