import { Tooltip, TooltipProps } from '@components/common/Tooltip/Tooltip';
import { cloneElementWithClass } from '@utils';
import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export type LinkSize = 'sm' | 'md' | 'lg';

// button: uses <button> for actions using onClick handler
// link: uses <a> (React Router <Link>)
// span: functionally the same as button, but is inline
type LinkAsComponentTypes = 'button' | 'link' | 'span';

type Props = {
  children: ReactNode;
  href?: string;
  as?: LinkAsComponentTypes;
  onClick?: (event: MouseEvent) => void;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  disabledTooltip?: TooltipProps;
  /**
   * Based on Tailwind font size
   * @see https://tailwindcss.com/docs/font-size
   */
  fontSize?: string;
  /**
   * Based on Tailwind text color class
   * @see https://tailwindcss.com/docs/color
   */
  textColor?: string;
  size?: LinkSize;
  dataTestId?: string;
};

const fontSizeMap: Record<LinkSize, string> = {
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-base',
};

export const Link = ({
  href,
  children,
  onClick,
  icon,
  iconPosition = 'right',
  disabled,
  disabledTooltip,
  textColor = 'text-gray-900',
  size = 'md',
  dataTestId,
  as = 'link',
}: Props) => {
  if ((as === 'button' || as === 'span') && href) {
    throw new Error(
      `Link: href has no effect when as="${as}", use onClick instead`,
    );
  }

  if (disabled && !disabledTooltip) {
    throw new Error('Link: disabledTooltip is required when disabled');
  }

  const Icon = icon ? cloneElementWithClass(icon, 'w-5 h-5') : null;

  const renderContent = () => (
    <>
      {iconPosition === 'left' && Icon}
      {children}
      {iconPosition === 'right' && Icon}
    </>
  );

  const textStyles = 'font-semibold underline underline-offset-3';
  const focusStyles =
    'focus:ring-product-dark focus-visible:ring-product-dark focus:ring-2 focus:ring-offset-2 focus:outline-hidden';

  let content;

  if (as === 'button') {
    content = (
      <button
        onClick={(event) => {
          event.preventDefault();
          onClick?.(event);
        }}
        disabled={disabled}
        className={clsx(
          'flex disabled:text-gray-500',
          icon && 'items-center gap-2',
          !disabled && 'hover:opacity-80',
          textColor,
          size && fontSizeMap[size],
          focusStyles,
          textStyles,
        )}
        data-testid={dataTestId}
      >
        {renderContent()}
      </button>
    );
  } else if (as === 'link') {
    content = (
      <ReactRouterLink
        to={href ?? '#'}
        className={clsx(
          icon && 'flex items-center gap-2',
          disabled ? 'pointer-events-none !text-gray-500' : 'hover:opacity-80',
          textColor,
          size && fontSizeMap[size],
          focusStyles,
          textStyles,
        )}
        data-testid={dataTestId}
      >
        {renderContent()}
      </ReactRouterLink>
    );
  } else if (as === 'span') {
    content = (
      <span
        tabIndex={disabled ? undefined : 0}
        role="link"
        onClick={(event) => {
          if (disabled) return;
          onClick?.(event);
        }}
        className={clsx(
          icon && 'flex items-center gap-2',
          disabled
            ? '!cursor-default !text-gray-500'
            : clsx('!cursor-pointer hover:opacity-80', focusStyles),
          textColor,
          size && fontSizeMap[size],
          textStyles,
        )}
        data-testid={dataTestId}
      >
        {renderContent()}
      </span>
    );
  }

  if (disabled && disabledTooltip) {
    return <Tooltip {...disabledTooltip}>{content}</Tooltip>;
  }

  return content;
};
